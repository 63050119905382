"use client";
import { Button } from "@cgc/ui/button";
import { Logo } from "@cgc/ui/logo";
import dynamic from "next/dynamic";

const HomeLoginButton = dynamic(() => import("./home-login-button"), {
  ssr: false,
  loading: () => <Button disabled>Log In</Button>,
});

export function HomeNav() {
  return (
    <div className="absolute right-0 top-0 z-50 flex w-full justify-between p-3 lg:justify-end">
      <Logo className="h-14 w-14 drop-shadow-lg lg:hidden" circled />
      <div className="flex items-center justify-end gap-3">
        <a href="#got-a-discount">
          <Button outline>Redeem code</Button>
        </a>

        <HomeLoginButton />
      </div>
    </div>
  );
}
